import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import "../style/Profile.css";
import { FaCheckCircle } from 'react-icons/fa'; // Example using a Font Awesome icon

const Profile = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [profileData, setProfileData] = useState(null);
  const [name, setName] = useState('');
  const [university, setUniversity] = useState('');
  const [dormNumber, setdormNumber] = useState(''); // New state for dormNumber
  const [formActive, setFormActive] = useState(false); // State to track form interaction


  const universityCoordinates = {
    'Mekelle University': { lat: 13.4802482, lon: 39.4849729 },
    
    'Hawassa University': { lat: 7.06, lon: 38.48 },
    'Gondar University': { lat: 12.6, lon: 37.46 },
    'Arba Minch University': { lat: 6.03, lon: 37.55 },
    'Adama Science and Technology University': { lat: 8.563032, lon: 39.2899149 },
    'Addis Ababa Science and Technology University' :{ lat: 8.8842127, lon: 38.810389 },
    'Debre Berhan University': { lat: 9.67, lon: 39.52 },
    'Jimma University': { lat: 7.67, lon: 36.83 },

    'Addis Ababa City': { lat: 9.03, lon: 38.74 },

    'Bahir Dar University': { lat: 11.60, lon: 37.39 },
    'Dilla University': { lat: 6.42, lon: 38.31 },

    'Dire Dawa University': { lat: 9.6199874, lon: 41.839769 },

    'Debre Markos University': { lat: 10.37, lon: 37.73 },
    'Kombolcha University': { lat: 11.08, lon: 39.78 },
    'Woldia University': { lat: 11.52, lon: 39.60 },
    'Wollo University': { lat: 11.75, lon: 39.60 },
    'Haramaya University': { lat: 9.30, lon: 42.05 },
    'Gambela University': { lat: 8.25, lon: 34.58 },
    'Mettu University': { lat: 8.38, lon: 34.29 },
    'Shashemene University': { lat: 7.20, lon: 38.60 },
    'Bule Hora University': { lat: 7.08, lon: 39.61 },
  };

  const loadProfileData = () => {
    const storedProfile = localStorage.getItem('userProfile');
    if (storedProfile) {
      const parsedProfile = JSON.parse(storedProfile);
      setProfileData(parsedProfile);
      setName(parsedProfile.name);
      setUniversity(parsedProfile.university);
      setdormNumber(parsedProfile.dormNumber || ''); // Load dormNumber if exists
    } else {
      // Redirect to the registration page if no profile data is found
      navigate('/profile'); // Change this path to your actual registration page path
    }
  };

  const updateProfile = () => {
    const updatedProfile = { ...profileData, name, university, dormNumber }; // Include dormNumber in the update
    localStorage.setItem('userProfile', JSON.stringify(updatedProfile));
    setProfileData(updatedProfile);
    navigate('/')
    window.location.reload(); // Reload the page to reset the application state
  };
  

  const updateLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setProfileData((prevData) => ({
            ...prevData,
            lat: latitude,
            lon: longitude,
          }));
          
          // Optionally update local storage with new coordinates
          const updatedProfile = { ...profileData, lat: latitude, lon: longitude };
          localStorage.setItem('userProfile', JSON.stringify(updatedProfile));
        },
        (error) => {
         
          alert("Unable to retrieve your location. Please allow location access in your browser settings.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const clearProfile = () => {
    localStorage.clear(); // Clear all local storage data
    window.location.reload(); // Reload the page to reset the application state
    navigate(`/`);
  
  };

  useEffect(() => {
    loadProfileData();
  }, []);


const capitalizeFirstLetter = (name) => {
  if (!name) return '';
  return name.charAt(0).toUpperCase() + name.slice(1);
};

return (
  <div className="profile-container" style={{ maxWidth: '500px', margin: '0 auto' }}>
    {profileData ? (
      <div className="profile-card">
         <div className="profile-details">
         <h3>
            {capitalizeFirstLetter(profileData.name)}
            
            {/* Check if userId is valid */}
            {Number(profileData.userId) ? (
              
              // Display official icon if userId is found
              <FaCheckCircle style={{ marginLeft: '5px', color: 'green' }} />
            ) : (
              // Display "Unofficial Account" text if userId is not found
              <span style={{ marginLeft: '5px', color: 'red', fontStyle: 'italic' }}>Unofficial Account</span>
            )}
          </h3>
          
          <div>
          <p><strong>Phone Number:</strong> {profileData.phone}</p>
          <p><strong>Location:</strong> Lat: {profileData.lat || 'N/A'}, Lon: {profileData.lon || 'N/A'}</p>
          <p><strong>University / City:</strong> {profileData.university || 'N/A'}</p>
          <p><strong>Dorm Number / Block :</strong> {profileData.dormNumber || 'N/A'}</p>
          </div>

          {/* Form to update the profile */}
          <div className="profile-edit">
            <h4>Update Profile </h4>
            <label>
              Update Name:
             
            </label>
            <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setFormActive(true); // Set form active on input change
                }}
                placeholder="Enter new name"
              />
            <br />
            

            <label>
              Update University:
             
            </label>
            <select
                value={university}
                onChange={(e) => {
                  setUniversity(e.target.value);
                  setFormActive(true); // Set form active on dropdown change
                  const coordinates = universityCoordinates[e.target.value];
                  if (coordinates) {
                    // Update latitude and longitude based on selected university
                    setProfileData((prevData) => ({
                      ...prevData,
                      lat: coordinates.lat,
                      lon: coordinates.lon,
                    }));
                  }
                }}
              >
                <option value="">Select University</option>
                {Object.keys(universityCoordinates).map((univ) => (
                  <option key={univ} value={univ}>{univ}</option>
                ))}
              </select>
            <br />
            <label>
              Update Dorm/block/Building  Name & Number:
              
            </label>
            <input
                type="text"
                value={dormNumber}
                onChange={(e) => {
                  setdormNumber(e.target.value);
                  setFormActive(true); // Set form active on input change
                }}
                placeholder="Enter dorm number"
              />
            <br />
           
            
            {/* Conditionally render buttons based on form interaction */}
            {formActive && (
              <div className="button-row">
                <button onClick={updateProfile} className="update-button">Update Profile</button>
                <button onClick={updateLocation} className="update-location-button">Update Location</button>
                <button onClick={clearProfile} className="clear-profile-button">Delete Profile</button>
              </div>
            )}
              {/* Hint Text */}
              <div className="hint-text">
              <h4>User Hints</h4>
              <p><strong>Deleting Your Profile:</strong> If you decide to delete your profile, please keep in mind that this action is permanent. All your personal information, preferences, and any associated data will be irrevocably removed from our system. If you're sure about this decision, you can proceed to delete your profile through the settings menu. However, if you simply wish to take a break, consider temporarily deactivating your account instead, as this will allow you to return without losing any information.</p>
              <p><strong>Updating Your Profile:</strong> When you update your profile, you can modify various fields such as your name, email address, profile picture, and other personal details. To ensure that your changes are saved, please double-check all the information you've entered. After making your updates, don't forget to click the "Save Changes" button. This will refresh your profile with the new information, making it visible to other users as per your privacy settings.</p>
              <p><strong>Updating Your Location:</strong> If you want to update your location, it's important to enable location services on your device first. This allows our app to access your current geographical position, ensuring that the location you set is accurate. To update your location, go to the settings menu and select the location update option. Make sure your device's location services are turned on. Once your location is detected, you can confirm the new position and save it. This will help you connect better with nearby services and users.</p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <p className="no-profile">No profile data found. Please ensure you are logged in or have a saved profile.</p>
    )}
  </div>
);
};

export default Profile;
