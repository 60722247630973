import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrdersRequest, fetchOrdersSuccess, fetchOrdersFailure } from '../redux/actions/orderActions';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import '../style/showMyOrders.css';

function OrdersPage() {
  const [userId,setUserId]=useState()
  useEffect(() => {
    // Check if Telegram Web App is ready
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready(); // Signal that the app is ready
    }
  }, []);

  const dispatch = useDispatch();
  const { loading, orders, error } = useSelector((state) => state.orders);
  const [expandedOrderId, setExpandedOrderId] = useState(null);

  useEffect(() => {
    const retrievedProfile = localStorage.getItem('userProfile');
    if (retrievedProfile) {
      const userProfile = JSON.parse(retrievedProfile);
    
      setUserId(userProfile.userId);
      
    }
  }, []); // Run once when the component mounts
  
  useEffect(() => {
    // Ensure Telegram WebApp is available and userId is not already set
    if (!userId && window.Telegram && window.Telegram.WebApp) {
      const user = window.Telegram.WebApp.initDataUnsafe.user;
      if (user) {
        setUserId(user.id); // Set userId from Telegram WebApp
      } else {
        console.log("User ID is not available from Telegram Web App");
      }
    }
  }, [userId]); // Add userId as a dependency to check if it's updated

  useEffect(() => {
    const fetchOrders = async () => {
      dispatch(fetchOrdersRequest());
      try {
        const q = query(collection(db, 'Telegram-OrderdList'), where('user_id', '==', userId));
        const querySnapshot = await getDocs(q);
        const fetchedOrders = [];

        querySnapshot.forEach((doc) => {
          fetchedOrders.push({ id: doc.id, ...doc.data() });
        });

        dispatch(fetchOrdersSuccess(fetchedOrders));
      } catch (error) {
        dispatch(fetchOrdersFailure(error.message));
      }
    };

    fetchOrders();
  }, [dispatch, userId]);

  const toggleOrderItems = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="my-orders-container">
      {userId ? (  // Check if userId exists
        <div className="my-order-list">
          {orders.length === 0 ? (
            <p>No orders found.</p>
          ) : (
            orders.map((order) => (
              <div
                className={`order-card ${order.order_status === 'accepted' ? 'accepted' : ''} ${order.order_status === 'rejected' ? 'rejected' : ''}`}
                key={order.id}
                onClick={() => toggleOrderItems(order.id)}
              >
                <h2 className="order-name">From: {order.from_hotel}</h2>
                <p>Delivery Fee: {Number(order.delivery_fee).toFixed(0)} ETB</p>
                <p>Service Fee: {Number(order.service_fee).toFixed(0)} ETB</p>
                <p>
                  Total Price: {(
                    Number(order.totalAmount) +
                    Number(order.delivery_fee) +
                    Number(order.service_fee)
                  ).toFixed(0)} ETB
                </p>
                {order.order_status === 'accepted' && (
                  <div className="acceptance-notification">
                    <p className="acceptance-message">
                      <strong>Message:</strong> This order has been accepted.
                    </p>
                    <p className="acceptance-detail">
                      <strong>Accepted Time:</strong> {new Date(order.accepted_time).toLocaleString() || 'Not specified'}
                    </p>
                  </div>
                )}
                {order.order_status === 'rejected' && (
                  <div className="rejection-notification">
                    <p className="rejection-message">
                      <strong>Message:</strong> This order has been rejected.
                    </p>
                    {order.reject_message && (
                      <p className="rejection-detail">
                        <strong>Reason:</strong> {order.reject_message}
                      </p>
                    )}
                  </div>
                )}
                {expandedOrderId === order.id && (
                  <div className="order-items-my">
                    <h3>Order Items:</h3>
                    <div className="items-list-my">
                      {order.orders.map((item) => (
                        <div key={`${order.id}-${item.p_id}`} className="order-item-my">
                          <h4>{item.productName}</h4>
                          <p>Price: {item.price} ETB</p>
                          <p>Quantity: {item.quantity}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      ) : (
        <p>Your Telegram app is not an official app; you can't access your orders.</p>  // Add the note message here
      )}
    </div>
  );
  
}

export default OrdersPage;
